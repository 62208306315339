<template>
    <div class="grid lg:grid-cols-12 gap-10">
        <div class="lg:col-span-5">
            <UIHeading tag="h2" role="subtitle" class="mb-4">Auto Insights</UIHeading>
            <UIHeading tag="h3" role="main" class="mb-3">Irvine Auto Repair Expert Blog</UIHeading>

            <div class="text-lg font-semibold text-slate-800 md:mb-10">Professional car maintenance tips and advice from your trusted Irvine, CA auto repair specialists</div>

            <UButton to="/posts" variant="solid" size="xl" class="hidden md:inline-flex">Visit Our Blog</UButton>
        </div>
        <div class="lg:col-span-7">
            <div class="grid md:grid-cols-2 gap-11 items-start">
                <div v-for="post of posts" :key="post.uid" class="text-slate-800 flex flex-col justify-between gap-6">
                    <div>
                        <ULink>
                            <LazyPrismicImage :field="post.data.main_image.thumbnail" class="object-cover w-full rounded-0.5xl"/>
                        </ULink>
                    </div>
                    <div>
                        <h4 class="text-2xl font-bold mb-2">
                            <ULink :to="`/posts/${post.uid}`" class="h-11 inline-flex items-center hover:text-blue-700">{{post.data.title}}</ULink>
                        </h4>

                        <PrismicRichText :field="post.data.short_description"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="md:hidden">
            <UButton to="/posts" variant="solid" size="xl" class="w-full justify-center">Visit Our Blog</UButton>
        </div>
    </div>
</template>
<script setup>
const { client } = usePrismic();

const { data: posts, status } = await useAsyncData('posts', () =>
    client.getAllByType('posts', {
        page: 1,
        pageSize: 2
    })
);
</script>
